import { BuilderBlocks } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import { ChevronDown, Minus, PlusIcon } from "lucide-react";
import { useSearchParams } from "next/navigation";
import {
  Accordion as BaseAccordion,
  AccordionContent as BaseAccordionContent,
  AccordionItem as BaseAccordionItem,
  AccordionTrigger as BaseAccordionTrigger,
} from "~/components/ui/accordion";
import { TypographyDeprecated } from "~/components/ui/typography";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { cn } from "~/utils";

interface Props extends Required<BuilderDefaultProps> {
  variant: "default" | "with-status";
  itemOpen: number;
  itemsDefault: Array<{
    title: string;
    content: string;
    status?: never;
  }>;
  itemsWithStatus: Array<{
    title: string;
    content: string;
    status: "error" | "light-error" | "warning" | "light-success" | "success";
  }>;
}

const Accordion = ({
  variant = "default",
  itemsDefault = [],
  itemsWithStatus = [],
  itemOpen = 1,
  builderBlock,
}: Props) => {
  const params = useSearchParams();
  const paramItem = Number(params.get("accordionItem"));
  const activeItem =
    isNaN(paramItem) || !paramItem || paramItem === null
      ? undefined
      : paramItem;
  const items = variant === "default" ? itemsDefault : itemsWithStatus;

  const handleValueChange = (value: string) => {
    const params = new URLSearchParams();
    const newValue = Number(value.split("-")[1]);
    isNaN(newValue)
      ? params.delete("accordionItem")
      : params.set("accordionItem", newValue.toString());

    window.history.pushState({}, "", `?${params.toString()}`);

    const element = document.getElementById(
      `accordion-item-trigger-${newValue}`
    );
    const accordionElement = document.getElementById("accordion");
    let triggersHeight = 0;
    for (let i = 0; i < newValue; i++) {
      triggersHeight =
        triggersHeight +
        (document.getElementById(`accordion-item-trigger-${i}`)?.clientHeight ??
          0);
    }

    if (element) {
      const offset =
        accordionElement!.getBoundingClientRect().top +
        window.scrollY +
        triggersHeight;
      window.scrollTo({
        top: offset - 150,
        behavior: "smooth",
      });
    }
  };

  return (
    <BaseAccordion
      type="single"
      id="accordion"
      collapsible
      className="flex flex-col gap-2"
      defaultValue={`item-${itemOpen}`}
      value={activeItem === 0 ? undefined : `item-${activeItem}`}
      onValueChange={handleValueChange}
    >
      {items.map((item, index) => (
        <BaseAccordionItem
          id={`accordion-item-${index + 1}`}
          key={`item-${index + 1}`}
          value={`item-${index + 1}`}
        >
          <BaseAccordionTrigger
            id={`accordion-item-trigger-${index + 1}`}
            className={cn(
              "group bg-brand-primary-1 px-5 py-4 text-white data-[state=open]:bg-brand-primary-3 data-[state=open]:text-brand-primary-4",
              {
                "gap-3": variant === "with-status",
              }
            )}
          >
            {variant === "with-status" && (
              <>
                <PlusIcon className="size-6 self-start pt-1 transition-transform duration-200 group-data-[state=open]:hidden" />
                <Minus className="size-6 self-start pt-1 transition-transform duration-200  group-data-[state=closed]:hidden" />
              </>
            )}
            <div className="flex flex-1 flex-col justify-between gap-2 md:flex-row md:items-center md:gap-0">
              <div className="flex items-center justify-between">
                <TypographyDeprecated
                  variant="h5"
                  className="hidden md:block"
                  align="left"
                >
                  {item.title}
                </TypographyDeprecated>
                <TypographyDeprecated
                  variant="h6"
                  className="block md:hidden"
                  align="left"
                >
                  {item.title}
                </TypographyDeprecated>
                {variant === "with-status" && (
                  <ChevronDown className="size-6 shrink-0 transition-transform duration-200 group-data-[state=closed]:-rotate-90" />
                )}
              </div>

              {variant === "with-status" && (
                <div className="flex gap-2">
                  <TypographyDeprecated variant="subtitle" size="sm">
                    Status:
                  </TypographyDeprecated>
                  <div
                    data-status={item.status}
                    className={cn(
                      "size-5",
                      "rounded-full",
                      "border border-white",
                      "data-[status=error]:bg-error",
                      "data-[status=light-success]:bg-[#BFE189]",
                      "data-[status=light-error]:bg-[#FDA33A]",
                      "data-[status=success]:bg-[#8ACD1D]",
                      "data-[status=warning]:bg-warning"
                    )}
                  />
                </div>
              )}
            </div>
            {variant === "default" && (
              <ChevronDown className="size-6 shrink-0 transition-transform duration-200 group-data-[state=closed]:-rotate-90" />
            )}
          </BaseAccordionTrigger>
          <BaseAccordionContent className={cn("px-5 py-7")}>
            <BuilderBlocks
              parentElementId={builderBlock.id}
              dataPath={`component.options.${
                variant === "default" ? "itemsDefault" : "itemsWithStatus"
              }.${(activeItem ?? 0) - 1}.content`}
              blocks={item.content ?? []}
            />
            {variant === "with-status" && (
              <BaseAccordionTrigger className="mt-5 flex-none gap-2 text-brand-primary-1">
                <ChevronDown className="size-6 rotate-180" />
                <TypographyDeprecated variant="h6">
                  Collapse this section
                </TypographyDeprecated>
              </BaseAccordionTrigger>
            )}
          </BaseAccordionContent>
        </BaseAccordionItem>
      ))}
    </BaseAccordion>
  );
};

const registerAccordion = () => {
  Builder.registerComponent(Accordion, {
    name: "Accordion",
    inputs: [
      {
        name: "variant",
        type: "string",
        enum: ["default", "with-status"],
        defaultValue: "default",
      },
      {
        name: "itemOpen",
        type: "number",
        defaultValue: 1,
      },
      {
        name: "itemsDefault",
        showIf: (options) => options.get("variant") === "default",
        friendlyName: "Accordion Items",
        type: "list",
        defaultValue: [
          {
            title: "Section A",
            content: [],
            status: "success",
          },
        ],
        subFields: [
          {
            name: "title",
            type: "string",
            required: true,
          },
          {
            name: "content",
            type: "uiBlocks",
            defaultValue: [],
          },
        ],
      },
      {
        name: "itemsWithStatus",
        showIf: (options) => options.get("variant") === "with-status",
        friendlyName: "Accordion Items",
        type: "list",
        defaultValue: [
          {
            title: "Section A",
            content: [],
            status: "success",
          },
        ],
        subFields: [
          {
            name: "title",
            type: "string",
            required: true,
            defaultValue: "Section A",
          },
          {
            name: "content",
            type: "uiBlocks",
            hideFromUI: true,
            defaultValue: [],
          },
          {
            name: "status",
            type: "string",
            enum: [
              "error",
              "light-error",
              "warning",
              "light-success",
              "success",
            ],
            defaultValue: "success",
          },
        ],
      },
    ],
  });
};

export { Accordion, registerAccordion };
